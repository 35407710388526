type RouteType = { HOME: string; LOGIN: string };

const PRE_API_PATH: string = 'client';
const PRE_ROUTE_PATH: string = 'system';
const USERNAME_REGEX: RegExp = /^(?![0-9])(?=[a-z0-9._])$/;
const ROUTE: RouteType = {
  HOME: `/${PRE_ROUTE_PATH}`,
  LOGIN: '/auth/login'
};
const PHONE_NUMBER_PATTERN: string = '^((\\+885-?) |0)?[0-9]{8,12}$';

export const GLOBAL_VAR = { PRE_API_PATH, USERNAME_REGEX, ROUTE, PHONE_NUMBER_PATTERN, PRE_ROUTE_PATH } as const;
